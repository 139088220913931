import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';

export default function() {
  const title = 'Nuestro centro';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <h2>Sede Villa Urquiza - Belgrano</h2>
      <p>
        El centro odontológico Odontobios, ha sido especialmente diseñado para resolver en forma rápida y eficaz
        cualquier necesidad del paciente, desde la más sencilla hasta las de mayor complejidad.
      </p>
      <p>
        Al visitarnos, el paciente ingresará a un centro médico modelo, único tanto por sus características edilicias
        como por su funcionalidad y adelantos técnicos. El centro totalmente climatizado dispone de confortables salas
        de espera, ámbitos de relax pre-quirúrgico y música funcional.
      </p>
      <p>
        El centro odontológico Odontobios se encuentra ubicado en un sector estratégico de la ciudad de Buenos Aires,
        dada su cercanía con los barrios de Belgrano, Núñez, Saavedra, Villa Urquiza, Villa del Parque, Villa Devoto,
        Agronomía, Colegiales, Chacarita, Coghlan y Villa Ortúzar, entre otros.{' '}
      </p>
      <p>
        El centro se encuentra ubicado a 100 metros de las avenidas Triunvirato y Monroe, 50 metros de la la estación de
        Villa Urquiza del ferrocarril Mitre y a 100 metros de la estación Juan Manuel de Rosas de la línea B del subte.
        Además circulan en las proximidades más de 30 líneas de colectivos.
      </p>
      <div className="map-responsive">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6570.410322939849!2d-58.488903!3d-34.573675!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb65c2b9a9b55%3A0xdc5a5e308e9c931c!2sBauness+2584%2C+C1431DOD+CABA%2C+Argentina!5e0!3m2!1ses!2sus!4v1552763118299"
          width="600"
          height="450"
          frameBorder="0"
          title="centro"
          style={{ border: 0 }}
          allowFullScreen
        />
      </div>
    </Layout>
  );
}
